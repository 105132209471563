<template>
  <v-content>
    <v-container fluid grid-list-lg>
      <template v-for="(info, e) in newOrderInfo">
        <v-alert :key="info" :value="true" color="info" dismissible>
          Nuevo pedido
          <span class="font-weight-bold">{{ info.id }}, </span>
          para el dia de
          <span class="font-weight-bold">
            {{ info.date_shipping | moment('dddd DD') }} de {{ info.date_shipping | moment('MMMM') }}
          </span>
          <v-btn
            color="error"
            small
            absolute
            right
            style="bottom: 16px; right: 50px"
            @click="updateNewOrder(e, info.date_shipping)"
          >
            Actualizar pedidos
          </v-btn>
        </v-alert>
      </template>
      <v-layout row wrap>
        <v-flex xs12 sm8 md10>
          <v-card class="mb-4">
            <TitleCard>
              <template slot="title">
                Confeccion de productos
              </template>
              <template slot="subtitle">
                Para el dia {{ general.date | moment('dddd DD') }}
                de
                {{ general.date | moment('MMMM - YYYY') }}
              </template>

              <template slot="button">
                <v-badge left overlap>
                  <template v-slot:badge>
                    <span v-if="porHacer > 0">{{ porHacer }}</span>
                  </template>
                  <v-btn color="error" @click="filterProducts(null)"> Por hacer </v-btn>
                </v-badge>
                <v-badge left overlap>
                  <template v-slot:badge>
                    <span v-if="trabajando > 0">{{ trabajando }}</span>
                  </template>
                  <v-btn color="warning" @click="filterProducts(0)"> Trabajando </v-btn>
                </v-badge>
                <v-badge left overlap>
                  <template v-slot:badge>
                    <span v-if="listos > 0">{{ listos }}</span>
                  </template>
                  <v-btn color="success" icon @click="filterProducts(1)">
                    <v-icon>check</v-icon>
                  </v-btn>
                </v-badge>
                <v-badge left overlap>
                  <template v-slot:badge>
                    <span v-if="todos > 0">{{ todos }}</span>
                  </template>
                  <v-btn color="info" icon @click="filterProducts('all')">
                    <v-icon>done_all</v-icon>
                  </v-btn>
                </v-badge>
              </template>
            </TitleCard>
            <v-card-text>
              <v-layout v-if="products.length > 0" row wrap>
                <v-flex v-for="(p, i) in products" :key="i" xs12 sm12 md6 xl4>
                  <v-layout class="ma-0" row wrap>
                    <v-flex xs4>
                      <a href="#" @click="openDetail(p)">
                        <v-img :src="p.photo_small" aspect-ratio="1"></v-img>
                      </a>
                    </v-flex>
                    <v-flex xs8>
                      <h2
                        class="subheading"
                        :title="`${p.quantity} x ${p.name}`"
                        v-html="`${p.quantity > 1 ? p.quantity + ' x ' : ''}${p.name}`"
                      />
                      <p v-if="p.quantity > 1" class="body-2 error--text">Cantidad: {{ p.quantity }}</p>
                      <v-chip v-for="(a, key) in p.atributos" :key="key" label color="error" text-color="white">
                        <v-icon left>label</v-icon><span v-text="a.name" />
                      </v-chip>

                      <h3 class="body-2 mt-2">
                        Pedido
                        <router-link :to="{ name: 'orderIndex', params: { id: p.id } }">
                          {{ p.id }}
                        </router-link>
                      </h3>
                      <p class="caption mb-0" v-html="p.schedule.horario" />
                      <p class="caption" v-html="p.comuna" />
                      <v-alert :value="p.comment !== ''" color="info" v-html="p.comment" />
                      <v-alert :value="checkDuplicado(p.id) > 1" color="info" outline>
                        Este pedido tiene {{ checkDuplicado(p.id) }} productos
                      </v-alert>
                      <template v-if="!p.confeccion.status && p.confeccion.id === undefined">
                        <v-btn
                          :large="p.priority.name !== 'baja'"
                          class="ma-0 ml-0"
                          :color="priority(p.priority.name)"
                          @click="openDesigner(p, 0)"
                          >Asignar</v-btn
                        >
                      </template>
                      <template v-else-if="p.confeccion.status === 0">
                        <v-chip label small class="ma-0 mb-2" color="warning" text-color="white">
                          <TimeGo :number="p.confeccion.seconds"></TimeGo>
                          <span style="margin-left: 5px">
                            {{ `${p.confeccion.name}` }}
                          </span>
                        </v-chip>
                        <div></div>
                        <v-btn
                          :loading="loadingDialog"
                          flat
                          class="ma-0 mr-2"
                          color="warning"
                          @click="removeDesigner(p, 2)"
                          >Quitar</v-btn
                        >
                        <v-btn :loading="loadingDialog" large class="ma-0" color="success" @click="finishDesigner(p, 1)"
                          >Finalizar</v-btn
                        >
                      </template>
                      <template v-else-if="p.confeccion.status === 1">
                        <v-chip label small class="ma-0 mb-2" color="success" text-color="white"> Finalizado </v-chip>
                      </template>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout v-else row wrap>
                <v-flex xs12>
                  <v-alert :value="true" color="accent" icon="info" outline>
                    No hay productos para el dia
                    {{ general.date | moment('dddd DD') }}
                    de
                    {{ general.date | moment('MMMM - YYYY') }}
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <ProductosRedfloral api-key="1b7c5091-c912-45db-82df-6a4829344f8c" />
        </v-flex>
        <v-flex xs12 sm4 md2>
          <v-card v-if="activities.length > 0" color="warning" class="mb-3">
            <v-card-title class="text-xs-center" primary-title>
              <div>
                <div class="title white--text">Productos desactivados por control de stock</div>
              </div>
            </v-card-title>
            <v-card-text class="pt-0 text-xs-center">
              <v-btn depressed @click="openActivities">
                <span> Revisar ({{ activities.length }}) </span>
              </v-btn>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-title primary-title>
              <div>
                <h3 class="mb-1 title font-weight-regular">Color de rosas</h3>
                <div class="body-2 font-weight-light">Verifique los colores disponibles</div>
              </div>
            </v-card-title>
            <v-card-text class="py-0">
              <p>URLs de colores de rosas:</p>
              <a href="/venta-de-cajas-por-color" target="_blank">Rosas en cajas</a> <br />
              <a href="/atributos/8" target="_blank">Rosas en atributos</a>
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="checkColorFlowerrs()" color="error" flat block large @click="goLink()"> Verificar </v-btn>

              <v-btn v-else color="success" flat block large @click="goLink()"> Ya verificadas </v-btn>
            </v-card-actions>
          </v-card>

          <v-card hover>
            <TitleCard title="Pedidos por hacer" subtitle="De la semana" />
            <v-card-text class="body px-4 pb-4 pt-0 text-xs-center">
              <template v-for="(s, e) in semana">
                <div :key="s.label" @click="setDate(s.label)">
                  <h2 class="display-3">
                    {{ s.value }}
                  </h2>
                  <h3 class="subheading">
                    {{ s.label | moment('dddd DD') }} de
                    {{ s.label | moment('MMMM') }}
                  </h3>
                </div>
                <v-divider v-if="e + 1 !== semana.length" :key="`d-${s.label}`" class="mt-3" />
              </template>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialog.updateDesigner" max-width="500px" persistent>
      <v-card>
        <TitleDialog close :close-action="() => (dialog.updateDesigner = false)" :title="`Asignar diseñador`">
        </TitleDialog>
        <v-container class="pa-3" grid-list-lg>
          <v-alert
            v-if="productActive.quantity > 1"
            v-model="productActive.quantity"
            type="error"
            icon="warning"
            class="ml-0 mb-3"
          >
            <span>
              Recuerda la cantidad de productos:<br />
              <strong style="font-size: 1.2rem">Cantidad: {{ productActive.quantity }}<br /></strong>
              <strong style="font-size: 1.2rem">Producto: {{ productActive.name }}<br /></strong>
            </span>
          </v-alert>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select v-model="confeccionID" item-text="name" item-value="id" :items="disenadores" />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="pass"
                :disabled="!confeccionID"
                label="Ingrese su codigo"
                type="password"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="back" flat @click.stop="dialog.updateDesigner = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            flat
            :loading="loadingDialog"
            :disabled="loadingDialog"
            @click.native="updateDesigner(1)"
          >
            Asignar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.detail" max-width="800px">
      <v-card v-if="productActive">
        <TitleDialog close :close-action="() => (dialog.detail = false)" :title="productActive.name"></TitleDialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <template v-for="(img, i) in productActive.imgs">
              <v-flex :key="i" xs3>
                <img :src="img" style="max-width: 100%" alt="img.img" />
              </v-flex>
            </template>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <h2 class="title">Receta</h2>
              <ul class="ma-0">
                <template v-for="(r, i) in productActive.receta">
                  <li :key="i">{{ r.quantity }} x <span v-html="r.name"></span></li>
                </template>
              </ul>
              <v-btn color="primary" small :to="`/productos/${productActive.id_producto}/receta`">
                Cambiar Receta
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogActivities" width="500" scrollable persistent>
      <v-card>
        <TitleDialog :title="`Tienes ${activities.length} actividades`" />
        <v-card-text style="height: 300px" class="body-1 pa-0">
          <v-list two-line>
            <template v-for="(item, index) in activities">
              <v-list-tile
                :key="index"
                avatar
                ripple
                target="_blank"
                :to="{ name: 'product', params: { id: item.product.idproducto } }"
              >
                <v-list-tile-avatar>
                  <img :src="item.imagen.url" />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.product.titulo }}</v-list-tile-title>
                  <v-list-tile-sub-title class="text--primary">{{ item.message }}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    {{ $moment(item.date_add).format('DD-MM-YYYY HH:mm') }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider v-if="index + 1 < activities.length" :key="index"></v-divider>
            </template>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn flat color="back" @click="dialogActivities = false"> volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="postProductActivities()">
            <span>Marcar como vistos</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import FusionCharts from 'fusioncharts'
import TitleDialog from '../useful/titleDialog.vue'
import TitleCard from '../useful/titleCard.vue'
import TimeGo from '../useful/timeGo.vue'
import { GET_API, GET_STATUS_STORE, GET_DASHBOARD_TALLER, ORDER, GET_PRODUCT_ACTIVITIES } from '../../config'
import { auth } from '../../fireStore'
import Pusher from 'pusher-js'
import ProductosRedfloral from '../home/redfloral.vue'

// eslint-disable-next-line func-names
let handler = function(e) {
  const general = this.$store.getters.getGeneral
  general.date = this.$moment(this.$moment(e.data.categoryLabel, 'DD-MM-YYYY')).format('YYYY-MM-DD')
  this.$store.dispatch('setGeneral', general)
  this.$router.push({ name: 'pedidos' })
}

export default {
  name: 'Taller',
  components: { TitleDialog, TimeGo, TitleCard, ProductosRedfloral },
  metaInfo: {
    title: 'Inicio'
  },
  data() {
    return {
      dialogActivities: false,
      activities: [],
      statusDefault: null,
      products: [],
      productsAll: [],
      rows: [],
      semana: [],
      dialog: {
        statusStore: false,
        updateDesigner: false,
        detail: false
      },
      loadingDialog: false,
      confeccionID: 0,
      productActive: {},
      typeActive: 0,
      pass: null,
      newOrder: true,
      newOrderInfo: []
    }
  },
  computed: {
    porHacer() {
      const res = this._.filter(this.productsAll, ['confeccion.status', null])
      return res.length ? res.length : 0
    },
    trabajando() {
      const res = this._.filter(this.productsAll, ['confeccion.status', 0])
      return res.length
    },
    listos() {
      const res = this._.filter(this.productsAll, ['confeccion.status', 1])
      return res.length
    },
    todos() {
      const res = this.productsAll
      return res.length
    },
    disenadores() {
      return this.$store.getters.getDisenadores
    },
    user() {
      return this.$store.getters.getUser
    },
    general() {
      return this.$store.getters.getGeneral
    },
    colorFlowers() {
      return this.$store.getters.getColorFlowers
    },
    settings() {
      return this.$store.getters.getSettings
    }
  },
  watch: {
    all(e) {
      if (e) {
        this.listStateStatus.forEach(element => {
          this.selectedCommunesClose.push(element)
        })
      } else {
        this.selectedCommunesClose = []
      }
    },
    'general.date': function date() {
      this.getData()
    }
  },
  mounted() {
    if (this.$route.query.type) {
      if (this.$route.query.type === 'all') {
        this.statusDefault = this.$route.query.type
      } else {
        this.statusDefault = parseInt(this.$route.query.type)
      }
    }
    auth.onAuthStateChanged(userfirebase => {
      if (userfirebase && this._.size(this.user) > 0) {
        if (this.user.id !== null) {
          Pusher.logToConsole = false
          var pusher = new Pusher('2d2bbb39b2d97a956e31', {
            cluster: 'mt1'
          })
          pusher.subscribe(`orders-${this.user.shopID}`)
          pusher.bind('order_added', data => {
            const audio = new Audio(`${process.env.BASE_URL}sound/${this.settings.soundNewWhatsApp}`)
            audio.play()
            this.newOrder = true
            this.newOrderInfo.push(data)
          })(this)
        }
      }
    })
    this.getData()
    this.getProductActivities()
  },
  methods: {
    async postProductActivities() {
      console.log('product-activities')
      await this.$http({
        method: 'POST',
        url: GET_PRODUCT_ACTIVITIES
      })
      this.dialogActivities = false
      this.getProductActivities()
    },
    openActivities() {
      this.dialogActivities = true
    },
    async getProductActivities() {
      const res = await this.$http({
        method: 'GET',
        url: GET_PRODUCT_ACTIVITIES
      })
      this.activities = res.data
    },
    updateNewOrder(index, date) {
      this.setDate(date)
      this.newOrderInfo.splice(index, 1)
      this.getData()
    },
    checkColorFlowerrs() {
      const state = this.$store.getters.getColorFlowers !== this.$moment().format('YYYY-MM-DD')
      return state
    },
    goLink() {
      this.$store.dispatch('setColorFlowers', this.$moment().format('YYYY-MM-DD'))
    },
    shortName(s) {
      const count = 40
      return s.slice(0, count) + (s.length > count ? '...' : '')
    },
    async searchStatesSchedule() {
      try {
        const res = await this.$http.get(GET_STATUS_STORE, {
          params: {
            all: false
          }
        })
        this.avaibility = res.data
        this.selectedCommunesClose = []
        this.dialog.statusStore = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getData() {
      try {
        this.productsAll = []
        this.products = []
        const res = await this.$http.get(GET_DASHBOARD_TALLER, {
          params: {
            date: this.general.date
          }
        })
        this.productsAll = this._.filter(res.data.orders, ['elaboracion', 1])
        this.filterProducts(this.statusDefault)
        this.semana = res.data.semana
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updateScheduleComunas(type) {
      if (this.selectedCommunesClose.length > 0) {
        try {
          await this.$http.post(GET_STATUS_STORE, {
            type,
            communes: this.selectedCommunesClose
          })
          this.searchStatesSchedule()
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    async updateDesigner(type) {
      if (!this.pass && type === 1) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Debe ingresar un codigo'
        })
        return
      }
      this.loadingDialog = true
      const disenadorID = !this.typeActive ? this.confeccionID : this.productActive.confeccion.id
      try {
        const params = {
          type: this.typeActive,
          detailID: this.productActive.id_detalle,
          productID: this.productActive.id_producto,
          disenadorID
        }
        if (this.pass && type === 1) {
          params.pass = this.pass
        }
        const res = await this.$http.post(`${ORDER}/${this.productActive.id}/designer`, params)
        if (res.data.error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Codigo no corresponde'
          })
          return
        }
        this.getData()
        this.pass = null
        this.dialog.updateDesigner = false
        this.loadingDialog = false
      } catch (error) {
        this.loadingDialog = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    checkDuplicado(id) {
      const r = this._.filter(this.productsAll, ['id', id])
      return r.length
    },
    priority(e) {
      let r = 'orange white--text'
      if (e === 'media') {
        r = 'error'
      }
      if (e === 'alta') {
        r = 'error'
      }
      return r
    },
    disposed() {
      FusionCharts.removeEventListener('dataPlotClick', handler)
    },
    openDesigner(p, t) {
      this.dialog.updateDesigner = true
      this.productActive = p
      this.typeActive = t
    },
    finishDesigner(p, t) {
      this.productActive = p
      this.typeActive = t
      this.updateDesigner()
    },
    removeDesigner(p, t) {
      this.productActive = p
      this.typeActive = t
      this.updateDesigner()
    },
    filterProducts(type) {
      this.statusDefault = type
      if (this.productsAll.length > 0) {
        if (type === 'all') {
          this.$router.push({ name: 'taller', query: { type: 'all' } })
          this.products = this.productsAll
          return
        }
        const route = type === 1 || type === 0 ? { name: 'taller', query: { type } } : { name: 'taller' }
        this.$router.push(route)
        this.products = this._.filter(this.productsAll, ['confeccion.status', type])
      }
    },
    openDetail(p) {
      this.dialog.detail = true
      this.productActive = p
    },
    setDate(e) {
      const general = this.$store.getters.getGeneral
      general.date = e
      this.$store.dispatch('setGeneral', general)
    }
  }
}
</script>
