import { render, staticRenderFns } from "./redfloral.vue?vue&type=template&id=a6ecc2ba&"
import script from "./redfloral.vue?vue&type=script&lang=js&"
export * from "./redfloral.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib'
import { VBadge } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VChip } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VAlert,VBadge,VBtn,VCard,VCardText,VChip,VFlex,VImg,VLayout})
